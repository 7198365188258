import type { Banner, BannerEdit } from "~/models/Banners"
import type { EditCoin } from "~/models/Coin"
import type { Notifications } from "~/models/Notifications"
import type { Order } from "~/models/Order"
import type { PointOfSale } from "~/models/PointOfSale"
import type {
    CreateProductData,
    EditProductData,
} from "~/models/ProductCrudData"
import type { ShipmentSettingData } from "~/models/Shipment"

export function useCreateProductData() {
    return useState<CreateProductData | null>("createProductData", () => null)
}

export function useEditProductData() {
    return useState<EditProductData | null>("editProductData", () => null)
}

export function useEditOrderData() {
    return useState<Order | null>("editOrderData", () => null)
}

export function useEditPOSData() {
    return useState<PointOfSale | null>("editPOSData", () => null)
}

export function useNotificationsSettignsData() {
    return useState<Notifications | null>("notificationsSettings", () => null)
}
export function useShipmentSettignsData() {
    return useState<ShipmentSettingData | null>(
        "notificationsSettings",
        () => null
    )
}

export function useCustomizationSettingsData() {
    return useState("customizationSettings", () => null)
}

export function useEditCoinData() {
    return useState<EditCoin | null>("editCoinData", () => null)
}

export function useEditBannerData() {
    return useState<BannerEdit | null>("editBannerData", () => null)
}
